@tailwind base;
@tailwind components;
@tailwind utilities;


/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

:root {
	/* --heading-height: 10rem; */
	--heading-height: 0px;
}

.related-grid {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
	grid-auto-flow: column;
	grid-auto-columns: minmax(80%, 1fr);
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

@media (min-width: 640px) {
	/* sm */

}

@media (min-width: 768px) {
	/* md */

}

@media (min-width: 1024px) {

	/* lg */
	.related-grid {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
		grid-auto-flow: column;
		grid-auto-columns: minmax(33%, 1fr);
	}
}

@media (min-width: 1280px) {
	/* xl */

}

@media (min-width: 1536px) {
	/* 2xl */

}


html,
body {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(to bottom,
			transparent,
			rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.es-widget-title-container, .es-header-component-top {
    display: none!important;
}

.grecaptcha-badge {
    display: none!important;
}

@layer base {
    iframe {
        max-width: 100%!important;
    }
    
	.button-wrapper,
	.button-wrapper * {
		transition: all .2s ease;
	}
	.button-wrapper:hover svg:first-child,
	.button-wrapper:hover svg:last-child {
		scale: 0.9;
	}

	.button-wrapper:hover button,
	.button-wrapper:hover a {
		@apply scale-105;
	}

	.button-wrapper,
	.button-wrapper * {
		transition: all .2s ease;
	}

	.button-wrapper:hover svg:first-child {
		@apply -translate-x-[16%] scale-90;
	}

	.button-wrapper:hover svg:last-child {
		@apply translate-x-[16%] scale-90;
	}

	.button-wrapper:hover button,
	.button-wrapper:hover a {
		@apply scale-105;
	}
    /* 
    input.lg\:w-1\/2:nth-of-type(odd) {
        @apply border-l-0 lg:border-l-2
    } */

    .h1 {
        @apply text-4xl text-primary-800 italic;
    }
    .h2 {
        @apply text-3xl text-primary-800;
    }
    .h3 {
        @apply text-2xl text-primary-800;
    }
    .h4 {
        @apply text-xl text-primary-800;
    }
    .h5 {
        @apply text-lg text-primary-800;
    }
    .h6 {
        @apply text-base text-primary-800;
    }
    .p {
        @apply text-base font-overpass text-white;
    }

	@media (min-width: 768px) {
        .h1 {
            @apply text-5xl text-primary-800 italic;
        }
        .h2 {
            @apply text-5xl text-primary-800;
        }
        .h3 {
            @apply text-4xl text-primary-800;
        }
        .h4 {
            @apply text-3xl text-primary-800;
        }
        .h5 {
            @apply text-2xl text-primary-800;
        }
        .h6 {
            @apply text-xl text-primary-800;
        }
        .p {
            @apply text-base font-overpass text-white;
        }
    }
    
    @media (min-width: 1024px) {
        .h1 {
            @apply text-6xl text-primary-800 italic;
        }
        .h2 {
            @apply text-5xl text-primary-800;
        }
        .h3 {
            @apply text-4xl text-primary-800;
        }
        .h4 {
            @apply text-3xl text-primary-800;
        }
        .h5 {
            @apply text-2xl text-primary-800;
        }
        .h6 {
            @apply text-xl text-primary-800;
        }
        .p {
            @apply text-base font-overpass text-white;
        }
    }

	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	.p {
		@apply font-bold;
	}

	.text-wrap {
		white-space: pre-wrap;
	}


	.markdown-wrapper {
		@apply font-bold text-base font-overpass text-white;
	}

	.markdown-wrapper h1 {
		@apply h1;
	}
    .markdown-wrapper h2 {
        @apply h2;
    }
    .markdown-wrapper h3 {
        @apply h3;
    }
    .markdown-wrapper h4 {
        @apply h4;
    }
    .markdown-wrapper h5 {
        @apply h5;
    }
    .markdown-wrapper h6 {
        @apply h6;
    }

	.markdown-wrapper ul {
		all: revert;
	}
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}